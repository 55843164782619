import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  return (
    <Navbar bg="light" shadow="lg" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/image/logo.jpeg"
            width="115"
            height="41"
            // 460 × 163
            className="d-inline-block align-top"
          />
          {/* Solaring Srl */}
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavDropdown id="nav-dropdown" title="AZIENDA" menuVariant="light">
              <NavDropdown.Item href="#action/3.1">CHI SIAMO</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">SEDE</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                CERTIFICAZIONI
              </NavDropdown.Item>

              <NavDropdown.Item href="#action/3.4">TEAM</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown id="nav-dropdown" title="SERVIZI" menuVariant="light">
              <NavDropdown.Item href="#action/3.1">
                PROGETTAZIONE FINO ALLA CANTIERIZZAZIONE
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                DIREZIONE LAVORI
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">CONSULENZE</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                ROGETTAZIONE E COORDINAMENTO PER LA SICUREZZA
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.5">
                ASSISTENZA DI CANTIERE
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown id="nav-dropdown" title="CONTATTI" menuVariant="light" >
              <NavDropdown.Item href="#action/3.1">
                segreteria@solaning.it
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                solaningsrl@gmail.com
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                solaningsrl@pec.it
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
