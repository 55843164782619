

import { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function WelcomeMission() {
  return (
    <Fragment>
    <div id="" className="container-fluid">
      <h1 className="titleSolaring">LA NOSTRA MISSION</h1>
      <p className="descrSolaring">SOLANING è una società di ingegneria che si occupa di progettazione nel vasto settore dell’energia.</p>
      <p className="descrSolaring">Obiettivo di Solaning è puntare sulle fonti rinnovabili andando al passo con i tempi e con quello che richiede il mercato che mira ad essere oggi sempre più green.</p>
      <p className="descrSolaring">Innovazione e sviluppo sostenibile ispirano dunque il team di professionisti ed esperti che la compongono, pronti ad offrire soluzioni personalizzate e di alta qualità per esaudire le richieste dei clienti e garantire la loro piena soddisfazione.</p>
      </div>
      </Fragment>

  );
}

export default WelcomeMission;