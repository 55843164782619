import { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function WelcomeFooter() {
  return (
    <Fragment>
      <div id="" className="container-fluid black">
      <div className="row footer-welcome">
          <div className="col-xs-6 col-sm-4">
        <h1 className="titleSolaring">Solaning srl</h1>
        <p className="descrSolaring">Viale Campania, 25</p>
        <p className="descrSolaring">P.I. 06926200822</p>
        <p className="descrSolaring">Mail: solaningsrl@gmail.com</p>
        <p className="descrSolaring">PEC: solaningsrl@pec.it </p>
        </div><div className="col-xs-6 col-sm-4">
        <h1 className="titleSolaring">Link utili</h1>
        <p className="descrSolaring">Privacy</p>
        <p className="descrSolaring">Ecc</p>
        
        </div><div className="col-xs-6 col-sm-4">
        <h1 className="titleSolaring">Social</h1>
        <p className="descrSolaring">Linkedin</p>
        <p className="descrSolaring">Instagram</p>
      
        </div> </div>
      </div>
    </Fragment>
  );
}

export default WelcomeFooter;
