import { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function WelcomeImage() {
  return (
    <Fragment>
    <div id="home-main" className="container-fluid">
      
      </div>
      </Fragment>

  );
}

export default WelcomeImage;